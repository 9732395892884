<template>
  <div class="main" :class="show ? 'h5main' : ''">
    <mtop />
    <div class="demo_top"></div>
    <div class="demo_box1">
      <div class="box1_top">
        <div class="img">
          <img src="" alt="" />
        </div>
        <div>
          您的当前位置： 网站首页 >
          <span @click="toPage('/gallery')">案例展示 </span>> 案例详情
        </div>
      </div>
    </div>
    <div class="demp_main">
      <div class="main_top">
        <div class="top_left">
          <div class="left1">&nbsp;&nbsp;&nbsp;{{ info.title }}</div>
          <div style="width: 497px; height: 2px; background: #f7f7f7"></div>
          <div class="left2">
            <div class="left2_item">
              <div>分类 :</div>
              <div class="inp">{{ info.project_cate_name }}</div>
            </div>
            <div class="left2_item">
              <div>平台类型 :</div>
              <div class="inp">{{ info.type == 1 ? "移动端" : "PC端" }}</div>
            </div>
            <div class="left2_item">
              <div>行业 :</div>
              <div class="inp">{{ info.craft_cate_name }}</div>
            </div>
            <div class="left2_item">
              <div>浏览量 :</div>
              <div class="inp">{{ info.click }}</div>
            </div>
            <div class="left2_item">
              <div>描述 :</div>
              <div class="inp des">
                {{ info.description }}
              </div>
            </div>
          </div>
          <div
            style="width: 497px;
              height: 2px;
              background: #f7f7f7;
              margin: 40px 0px;
            "
          ></div>
          <div class="left3">
            <div>查看演示</div>
            <div @click="toPage('/customizing')">立即咨询</div>
          </div>
        </div>
        <div class="top_right">
          <img :src="info.thumb" alt="" />
        </div>
      </div>
      <div
        style="width：100%;height:1px; background:#f7f7f7;margin:40px 0px;"
      ></div>
      <div class="main_exm">
        <h1 class="exm_title">项目介绍</h1>
        <h2 class="exm_tip">
          <!-- 本项目是APP，本项目是APP本项目是APP本项目是APP本项目是APP本项目是APP本项目是APP -->
         <div v-html="info.content"></div>
        </h2>
        <div class="exm_box" v-if="show">
          <!-- 移动端 -->
          <img
            :src="item"
            alt=""
            v-for="(item, index) in info.images"
            :key="index"
            class="appimg"
          />
        </div>
        <div class="exm_box" v-else>
          <!-- PC端 -->
          <img
            :src="item"
            alt=""
            v-for="(item, index) in info.images"
            :key="index"
            class="pcimg"
          />
        </div>

        <div class="exm_bot">
          声明：本文由【星润科技】编辑上传发布，转载此文章须经作者同意，并请附上出处【星润科技】
        </div>
        <!-- <div class="main_bottom">
               <div class="bottom_title">相关案例</div>
               <div class="box1_con">
                  <div class="con_item">
                     <div class="item_img">
                        <img src="../assets/indeximg/con1.png" alt="" />
                     </div>
                     <div class="item_title">高科技成果交易会</div>
                     <div class="item_con">
                        高交会，科技创新，是一家科技创新公司得官网
                     </div>
                     <div class="item_box">
                        <div>企业官网</div>
                        <div>科技</div>
                        <div>展会网站</div>
                     </div>
                  </div>
                  <div class="con_item">
                     <div class="item_img">
                        <img src="../assets/indeximg/con1.png" alt="" />
                     </div>
                     <div class="item_title">高科技成果交易会</div>
                     <div class="item_con">
                        高交会，科技创新，是一家科技创新公司得官网
                     </div>
                     <div class="item_box">
                        <div>企业官网</div>
                        <div>科技</div>
                        <div>展会网站</div>
                     </div>
                  </div>
                   
               </div>
            </div> -->
      </div>
    </div>
    <mbottom />
  </div>
</template>

<script>
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
export default {
  data() {
    return {
      info: {},
      id: null,
      list: [1, 2, 3, 4, 5, 6],
      active: "",
      show: false,
      name: "",
      formdata: {
        Page: 1,
        PageSize: "20",
      },

      loading: false,
      finished: false,
      refreshing: false,
      showlist: false,
      mylist: {},
      basemenu: "",
    };
  },
  components: {
    mtop,
    mbottom,
  },
  mounted() {
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  watch: {
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    deep: true,
    immediate: true,
  },
  created() {
    //
    if (this.$route.params.anliid) {
      this.id = this.$route.params.anliid;
      console.log(this.id);
      this.getdetail();
    } else {
      this.id = sessionStorage.getItem("anliid");
      this.getdetail();
    }
  },
  methods: {
    toPage(e) {
      this.$router.replace({ path: e });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getdetail() {
      this.$axios({
        method: "get",
        url: "/v1/casusRead",
        params: { id: this.id },
      })
        .then((res) => {
          this.info = res.data.data.info;
        })
        .catch(() => {
          console.log("请求失败");
        });
    },

    goopen(e) {
      let url =
        "https://opensea.io/assets/matic/0xb5e200d739bc792e273b2beb6dea9fd918ede79b/" +
        e;
      window.open(url);
    },
    onRefresh() {
      this.formdata.Page = 1;
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.formdata.Page == 1) {
        this.list = [];
        this.refreshing = false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
$t-mf: "Arial";

.el-icon-arrow-down:before {
  content: "";
}
.main {
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  min-width: 1200px;
  max-width: 100%;
  .demo_top {
    width: 100%;
    height: 85px;
    background: #545454;
  }
  .demo_box1 {
    width: 1200px;
    margin: 0 auto;
    .box1_top {
      display: flex;
      text-align: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 50px;

      align-items: center;
      .img {
        width: 24px;
        height: 24px;
        background: url("../assets/indeximg/point.png");
        background-size: 100% 100%;
        margin-right: 12px;
      }
      div > span {
        color: #0052da;
        cursor: pointer;
      }
    }
    .box1_bottom {
      width: 1200px;
      height: 219px;
      background: #ffffff;
      border: 1px solid #f3f3f3;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 40px 67px;
      justify-content: center;
      align-items: center;
      .bot_item {
        display: flex;
        border-bottom: 1px solid #eee;
        width: 100%;
        margin: 0 auto;
        .tap_title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          height: 67px;
          line-height: 67px;
          width: 87px;
          text-align: center;
          border-right: 1px solid #eee;
        }
        .tap {
          display: flex;
          align-content: center;
          justify-content: center;

          div {
            height: 67px;
            line-height: 67px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            padding: 0 22px;
          }
          .tab_act {
            border: 1px solid #0052da;
            border-radius: 2px;
            text-align: center;
            line-height: 28px;
            height: 28px;
            color: #0052da;
            margin-top: 18px;
          }
        }
      }
    }
    .box2 {
      width: 1200px;
      margin: 0 auto;
      margin-top: 47px;
      .box1_con {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .con_item {
          display: flex;
          flex-direction: column;
          width: 382px;
          height: 366px;
          border: 1px solid #e0e0e0;
          margin-bottom: 20px;
          .item_img {
            width: 382px;
            height: 257px;
            background: red;
          }
          .item_title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: left;
            padding-left: 20px;
            padding-top: 20px;
          }
          .item_con {
            text-align: left;
            padding-left: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
          }
          .item_box {
            display: flex;
            padding-left: 20px;
            div {
              width: 56px;
              height: 22px;
              border: 1px solid #dadada;
              border-radius: 2px;
              text-align: center;
              margin-right: 5px;
              line-height: 22px;
              font-size: 10px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .con_item:hover {
          box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
          transform: scale(1.02) translateZ(0);
          // transform:perspective(1px) scale(1.02) translateZ(0);
          // transition: all 0.5s;
        }
      }
    }
  }
  .demp_main {
    width: 1200px;
    margin: 0 auto;
    background: #ffffff;
    box-sizing: border-box;
    padding: 50px 50px;

    .main_top {
      display: flex;
      justify-content: space-between;
      .top_left {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        // padding:0px 50px;
        .left1 {
          border-left: 4px solid #0052da;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          padding-left: 20px;
          margin-bottom: 26px;
          text-align: left;
        }
        .left2 {
          .left2_item {
            width: 499px;
            height: 40px;
            opacity: 0.67;
            background: #fff;
            display: flex;
            line-height: 40px;
            div {
              width: 60px;
              height: 12px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000;
              text-align: right;
            }
            .inp {
              border: none;
              height: 40px;
              padding-left: 30px;
              width: 315px;
            }
            .des {
              text-align: left;
              height: 45px;
              width: 400px;
              font-size: 14px;
              cursor: default;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 32px;
              padding: 5px 31px;
            }
          }
          .left2_item:nth-child(even) {
            background: #f7f7f7;
            opacity: 0.67;
            .inp {
              border: none;
              height: 40px;
              padding-left: 30px;
              background: #f7f7f7;
              opacity: 0.67;
            }
          }
        }
        .left3 {
          display: flex;
          justify-content: space-between;
          // margin-top: 30px;
          box-sizing: border-box;
          padding: 0 20px;
          div {
            width: 220px;
            height: 36px;
            background: #0052da;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
          }
          div:nth-child(2) {
            background: #ec7265;
          }
          div:hover {
            transform: scale(1.02);
            box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
          }
        }
      }
      .top_right {
        width: 550px;
        height: 410px;
        // background: pink;
      }
    }
    .main_exm {
      .exm_title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
        text-align: left;
      }
      .exm_tip {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #242a37;
        text-align: left;
        line-height: 50px;
      }
      .exm_box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .appimg {
          width: 100%;
          margin-bottom: 5px;
          height: 100%;
          // border: 1px solid #000;
        }
        min-height: 786px;
        .pcimg {
          width: 1094px;
          //   width:540px;
          margin-bottom: 5px;
          //   background-size:540px;
          // width:50%;
          // box-sizing: border-box;
          //   border: 1px solid #000;
        }
      }
      .exm_bot {
        margin-top: 47px;
        margin-bottom: 60px;
        // width: 100%;
        height: 50px;
        background: #f6fcfc;
        line-height: 50px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-align: left;
        padding: 20px;
        color: #999999;
      }
      .main_bottom {
        display: flex;
        flex-direction: column;

        .bottom_title {
          margin-bottom: 40px;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
          border-left: 4px solid #0052da;
          text-align: left;
          padding-left: 11px;
        }
        .box1_con {
          width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: left;
          flex-wrap: wrap;
          .con_item {
            display: flex;
            flex-direction: column;
            width: 391px;
            height: 366px;
            border: 1px solid #e0e0e0;

            .item_img {
              width: 390px;
              height: 257px;
              background: red;
            }
            .item_title {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              text-align: left;
              padding-left: 20px;
              padding-top: 20px;
            }
            .item_con {
              text-align: left;
              padding-left: 20px;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 36px;
            }
            .item_box {
              display: flex;
              padding-left: 20px;
              div {
                width: 56px;
                height: 22px;
                border: 1px solid #dadada;
                border-radius: 2px;
                text-align: center;
                margin-right: 5px;
                line-height: 22px;
                font-size: 10px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
              }
            }
          }
          .con_item:hover {
            box-shadow: 0px 0px 0.28rem 0px rgb(0 0 0 / 19%);
            transform: scale(1.02) translateZ(0);
            // transform:perspective(1px) scale(1.02) translateZ(0);
            // transition: all 0.5s;
          }
          .con_item:nth-child(even) {
            margin: 0 20px;
          }
        }
        // .box1_con:nth-child(even) {
        //    border:1px solid blue;
        // }
      }
    }
  }
}
.h5main {
  width: 100%;
  min-width: 100%;
  .demo_top {
    height: 125px;
  }
  .demo_box1 {
    width: 100%;
    .box1_top {
      line-height: 88px;
      font-size: 26px;
      padding-left: 10px;
    }
    .box1_bottom {
      width: 100%;
      padding: 10px;
      height: auto;
    }
  }
  .demp_main {
    width: 100%;
    .main_top {
      flex-direction: column-reverse;
      .top_right {
        width: 100%;
        height: auto;
        img {
          width: 100%;
        }
      }
      .top_left {
        margin-top: 30px;
        .left1 {
          font-size: 32px;
        }
        .left2 {
          .left2_item {
            width: 100%;
            height: 88px;
            line-height: 88px;
            align-items: center;
            justify-content: space-between;
            div {
              height: 88px;
              width: auto;
              text-align: left;
              font-size: 26px;
            }
            .inp {
              height: 88px;
              text-align: right;
              padding-right: 20px;
              font-size: 26px;
            }
          }
          .left2_item:nth-child(even) .inp {
            height: 88px;
          }
        }
        .left3 {
          div {
            height: 68px;
            line-height: 68px;
            font-size: 26px;
          }
        }
      }
    }
    .main_exm {
      .exm_title {
        font-size: 32px;
      }
      .exm_tip {
        font-size: 26px;
        margin-bottom: 20px;
      }
      .exm_box {
        height: auto;
        .pcimg {
          width: 100%;
        }
      }
      .exm_bot {
        height: auto;
        font-size: 24px;
      }
    }
  }
}
</style>